import React from "react";
import PageWrapper from "../components/PageWrapper";
import { ROUTE_LINKS } from "../utils/routes";
import SEO from "../components/SEO";
import { offersInformation, aboutMatrix } from "../utils/constants";

interface Props {}

const Careers: React.FC<Props> = (props: Props) => {
  return (
    <PageWrapper path={ROUTE_LINKS.careers}>
      <SEO pageUrl={ROUTE_LINKS.careers} />
      <main className="max-w-6xl md:mx-auto p-2.5 pb-12">
        <h1 className="font-bold mb-10 heading lg:text-large text-semiLarge">
          New Jobs Coming Soon
        </h1>
        <div className="flex flex-col w-full">
          <span className="font-bold text-lg">
            Matrix Rental Solutions offers employees many perks including but
            not limited to:
          </span>
          <ul className="list-disc ml-8 mt-2">
            {offersInformation.map((item, index) => (
              <li className="mt-1">{item}</li>
            ))}
          </ul>
          <span className="font-bold text-lg mt-4">
            About Matrix Rental Solutions:
          </span>
          <span className="ml-4 mt-2">{aboutMatrix}</span>
        </div>
      </main>
    </PageWrapper>
  );
};

export default React.memo(Careers);
